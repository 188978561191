import React from "react";
import "../App.css";
import tick from "../images/tick.png";

function ProjectPlan() {
  return (
    <div className="projectPlanBg" id="plan">
      <div className="container">
        <div className="freespace"></div>

        <div className="row">
          <div className="col-md-12">
            <h1 className="plan">Project Plan</h1>
            {/* <p className="lorem">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p> */}
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-4">
            <div className="planBg">
              <div className="starterpack mb-2">
                <p style={{ fontSize: "18px", color: "#c9fa60" }}>Basic Plan</p>
                <p style={{ fontSize: "21px" }}>Starter App</p>
              </div>

              <div
                style={{
                  minHeight: "325px",
                }}
              >
                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      3-Page App (Home, About, Contact)
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Responsive Design
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">Basic SEO Setup</p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Contact Form Integration
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      1 Round of Revisions
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      7 Days Turnaround Time
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <hr />
              </div>

              <a
                href="https://www.fiverr.com/s/YPbGoa"
                target="_blank"
                className="choosePlan py-2"
              >
                <p>Get a Quote</p>
              </a>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className="planBg"
              style={{ boxShadow: "0px 0px 25px 5px #fff2" }}
            >
              <div
                className="starterpack mb-2"
                style={{
                  background: "#c9fa60",
                }}
              >
                <p style={{ fontSize: "18px", color: "#6f4bff" }}>
                  Standard Plan
                </p>
                <p style={{ fontSize: "21px", color: "black" }}>Business App</p>
              </div>

              <div
                style={{
                  minHeight: "325px",
                }}
              >
                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      5-Page Website (Home, About, Services, Portfolio/Gallery,
                      Contact)
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Responsive Design
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      On-Page SEO Optimization
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Contact Form & Newsletter Integration
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Social Media Links Integration
                    </p>
                  </div>
                </div>
                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      2 Round of Revisions
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      14 Days Turnaround Time
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <hr />
              </div>
              <a
                href="https://www.fiverr.com/s/YPbGoa"
                target="_blank"
                className="choosePlan py-2"
              >
                <p>Get a Quote</p>
              </a>
            </div>
          </div>

          <div className="col-md-4">
            <div className="planBg">
              <div className="starterpack mb-2">
                <p style={{ fontSize: "18px", color: "#c9fa60" }}>
                  Premium Plan
                </p>
                <p style={{ fontSize: "21px" }}>E-commerce App</p>
              </div>

              <div
                style={{
                  minHeight: "325px",
                }}
              >
                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Up to 15 Product Pages
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Responsive Design
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Complete SEO Setup
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Shopping Cart & Payment Gateway Integration
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Product Showcase & Categories
                    </p>
                  </div>
                </div>
                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      Customer Account Setup
                    </p>
                  </div>
                </div>
                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      3 Round of Revisions
                    </p>
                  </div>
                </div>

                <div className="placeholderGroup">
                  <div
                    className="pt-3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <img className="" src={tick}></img>
                    <p className="m-0 pl-2 placeholderText">
                      21 Days Turnaround Time
                    </p>
                  </div>
                </div>
              </div>

              <div className="my-4">
                <hr />
              </div>
              <a
                href="https://www.fiverr.com/s/YPbGoa"
                target="_blank"
                className="choosePlan py-2"
              >
                <p>Get a Quote</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectPlan;
