import React from "react";
import "../App.css";
import github from "../images/github.png";
import twitter from "../images/twitter.png";
import linkdin from "../images/linkedin.png";
import whatsapp from "../images/whatsapp.png";
import mail from "../images/mail.png";
import phone from "../images/phone.png";
import plane from "../images/plane.png";
import location from "../images/location.png";
import insta from "../images/insta.png";
import codepen from "../images/codepen.png";
// import Fade from "react-reveal/Fade";
import arrow from "../images/arrow.png";

function Footer() {
  return (
    <div className="footer" id="contact">
      {/* <div className="freespace"></div> */}
      <div className="container">
        <div className="row pt-5 pb-5">
          {/* <Fade bottom> */}
          <div className="col-md-12 text-center getintouch pt-5 pb-5">
            <div
              style={{
                height: "1px",
                width: "250px",
                backgroundColor: "#6F4BFF",
                margin: "20px",
              }}
            ></div>

            <div className=" mt-4 mb-3">
              <a href="https://www.fiverr.com/s/YPbGoa" target="_blank">
                <div className="getInTouchBtn mt-5">
                  <p>Get In Touch</p>
                  <img className="pl-2" src={arrow}></img>
                </div>
              </a>
            </div>
          </div>
          {/* </Fade> */}
        </div>
      </div>
    </div>
  );
}
export default Footer;
