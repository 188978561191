import React, { useState } from "react";
import "../App.css";

// import Fade from "react-reveal/Fade";
import arrow from "../images/arrow.png";

import teamboxvideo from "../videos/Teambox.mp4";
import teamboxposter from "../images/teambox-poster.svg";

import survivorquestvideo from "../videos/Survivor quest.mp4";
import survivorquestposter from "../images/Survivor quest-poster.svg";

import bloomedvideo from "../videos/bloomed.mp4";
import bloomedposter from "../images/bloomed-poster.svg";

import dreamFvideo from "../videos/dreamF.mp4";
import dreamFposter from "../images/dreamF-poster.png";

// import poppivideo from "../videos/poppi.mp4";
import poppiposter from "../images/poppi-poster.svg";

import fundsvideo from "../videos/4x Funds.mp4";
import fundsposter from "../images/funds-poster.png";

function Blogs() {
  const [teambox, setTeambox] = useState(
    <img
      src={teamboxposter}
      style={{
        width: "100%",
        height: "260px",
        borderRadius: "0px",
        objectFit: "cover",
      }}
    />
  );
  const [survivorquest, setSurvivorquest] = useState(
    <img
      src={survivorquestposter}
      style={{
        width: "100%",
        height: "260px",
        borderRadius: "0px",
        objectFit: "cover",
      }}
    />
  );
  const [bloomed, setBloomed] = useState(
    <img
      src={bloomedposter}
      style={{
        width: "100%",
        height: "260px",
        borderRadius: "0px",
        objectFit: "cover",
      }}
    />
  );
  const [dreamF, setDreamF] = useState(
    <img
      src={dreamFposter}
      style={{
        width: "100%",
        height: "260px",
        borderRadius: "0px",
        objectFit: "cover",
      }}
    />
  );
  const [poppi, setPoppi] = useState(
    <img
      src={poppiposter}
      style={{
        width: "100%",
        height: "260px",
        borderRadius: "0px",
        objectFit: "cover",
      }}
    />
  );
  const [funds, setFunds] = useState(
    <img
      src={fundsposter}
      style={{
        width: "100%",
        height: "260px",
        borderRadius: "0px",
        objectFit: "cover",
      }}
    />
  );

  return (
    <div className="blogBg">
      <div className="container">
        <div className="freespace"></div>
        <div className="row pt-5" id="portfolio">
          {/* <Fade bottom> */}
          <div className="col-md-12">
            {/* <div> */}
            <h1 className="Ha">My Portfolio</h1>
          </div>
          {/* </Fade> */}
        </div>

        <div>
          {/* <Fade bottom> */}
          <div className="row pt-5">
            {/* bloom-ed */}
            <div className="col-md-6 my-3">
              <div
                className="singlePortfolio"
                onMouseEnter={() =>
                  setBloomed(
                    <video
                      src={bloomedvideo}
                      playsInline
                      autoPlay
                      muted
                      loop
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                        display: "block",
                      }}
                    ></video>
                  )
                }
                onMouseLeave={() =>
                  setBloomed(
                    <img
                      src={bloomedposter}
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                      }}
                    />
                  )
                }
              >
                <div>{bloomed}</div>
                <div
                  className="pt-4 nameandDes"
                  style={{ color: "white", fontFamily: "spectral-bold" }}
                >
                  <h1>BloomEd</h1>
                  <p>
                    This app transforms education by automating student progress
                    tracking and report generation, simplifying the process and
                    boosting learning outcomes.
                  </p>
                </div>
              </div>
            </div>

            {/* Dream Factory */}
            <div className="col-md-6 my-3">
              <div
                className="singlePortfolio"
                onMouseEnter={() =>
                  setDreamF(
                    <video
                      src={dreamFvideo}
                      playsInline
                      autoPlay
                      muted
                      loop
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                        display: "block",
                      }}
                    ></video>
                  )
                }
                onMouseLeave={() =>
                  setDreamF(
                    <img
                      src={dreamFposter}
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                      }}
                    />
                  )
                }
              >
                <div>{dreamF}</div>
                <div
                  className="pt-4 nameandDes"
                  style={{ color: "white", fontFamily: "spectral-bold" }}
                >
                  <h1>Dream Factory</h1>
                  <p>
                    Revolutionize API development with the quickest and most
                    secure solution, empowering developers and businesses to
                    create seamless connections.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-5 mobileGroup">
            {/* teambox */}
            <div className="col-md-6 my-3">
              <div
                className="singlePortfolio"
                onMouseEnter={() =>
                  setTeambox(
                    <video
                      src={teamboxvideo}
                      playsInline
                      autoPlay
                      muted
                      loop
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                        display: "block",
                      }}
                    ></video>
                  )
                }
                onMouseLeave={() =>
                  setTeambox(
                    <img
                      src={teamboxposter}
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                      }}
                    />
                  )
                }
              >
                <div>{teambox}</div>
                <div
                  className="pt-4 nameandDes"
                  style={{ color: "white", fontFamily: "spectral-bold" }}
                >
                  <h1>Teambox</h1>
                  <p>
                    Elevate team management with an app designed for precision
                    and efficiency, ensuring teams thrive in their collaborative
                    efforts.
                  </p>
                </div>
              </div>
            </div>

            {/* survivor quest */}
            <div className="col-md-6 my-3">
              <div
                className="singlePortfolio"
                onMouseEnter={() =>
                  setSurvivorquest(
                    <video
                      src={survivorquestvideo}
                      playsInline
                      autoPlay
                      muted
                      loop
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                        display: "block",
                      }}
                    ></video>
                  )
                }
                onMouseLeave={() =>
                  setSurvivorquest(
                    <img
                      src={survivorquestposter}
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                      }}
                    />
                  )
                }
              >
                <div>{survivorquest}</div>
                <div
                  className="pt-4 nameandDes"
                  style={{ color: "white", fontFamily: "spectral-bold" }}
                >
                  <h1>Survivor quest</h1>
                  <p>
                    Spice up your parties with engaging quiz games, fostering
                    fun and interactive entertainment for all your guests.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-5 mobileGroup">
            {/* poppi */}
            <div className="col-md-6 my-3">
              <div
                className="singlePortfolio"
                // onMouseEnter={() =>
                //   setPoppi(
                //     <video
                //       src={poppivideo}
                //       playsInline
                //       autoPlay
                //       muted
                //       loop
                //       style={{
                //         width: "100%",
                //         height: "260px",
                //         borderRadius: "0px",
                //         objectFit: "cover",
                //         display: "block",
                //       }}
                //     ></video>
                //   )
                // }
                onMouseLeave={() =>
                  setPoppi(
                    <img
                      src={poppiposter}
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                      }}
                    />
                  )
                }
              >
                <div>{poppi}</div>
                <div
                  className="pt-4 nameandDes"
                  style={{ color: "white", fontFamily: "spectral-bold" }}
                >
                  <h1>Poppi</h1>
                  <p>
                    Simplify family communication and grocery management,
                    bringing harmony to household organization through a single,
                    user-friendly app.
                  </p>
                </div>
              </div>
            </div>

            {/* 4xfunds */}
            <div className="col-md-6 my-3">
              <div
                className="singlePortfolio"
                onMouseEnter={() =>
                  setFunds(
                    <video
                      src={fundsvideo}
                      playsInline
                      autoPlay
                      muted
                      loop
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                        display: "block",
                      }}
                    ></video>
                  )
                }
                onMouseLeave={() =>
                  setFunds(
                    <img
                      src={fundsposter}
                      style={{
                        width: "100%",
                        height: "260px",
                        borderRadius: "0px",
                        objectFit: "cover",
                      }}
                    />
                  )
                }
              >
                <div>{funds}</div>
                <div
                  className="pt-4 nameandDes"
                  style={{ color: "white", fontFamily: "spectral-bold" }}
                >
                  <h1>4xFunds</h1>
                  <p>
                    Empower agencies to effortlessly manage client funds in the
                    forex market, facilitating transparent profit tracking and
                    enhanced returns for clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </Fade> */}
        </div>
      </div>
    </div>
  );
}
export default Blogs;
