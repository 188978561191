import React, { useEffect, useState } from "react";
import "../App.css";
import phone from "../images/phone.png";
import linkdin from "../images/linkedin.png";
import whatsapp from "../images/whatsapp.png";
import mail from "../images/mail.png";
import arrow from "../images/arrow.png";
import TextLoop from "react-text-loop";

import svg1 from "../images/1.svg";
import svg2 from "../images/2.svg";
import svg3 from "../images/3.svg";
import svg4 from "../images/4.svg";
import svg5 from "../images/5.svg";

import sliderbg from "../images/sliderbg.png";

function Banner() {
  const imagenames = [svg1, svg2, svg3, svg4, svg5];

  return (
    <div className="banerBg" id="experience">
      <div className="container pt-5 mt-5 mobileres">
        <div className="row awaisbhiintro pb-5 pt-5 mt-5">
          <div className="col-md-7  pb-5 pl-0 mobilebanner">
            <p className="name">Expert in Bubble.io development</p>
            <h1 className="expert">The Quick & Intuitive Way To Build</h1>
            <TextLoop>
              <h1 className="networks">Social Networks</h1>
              <h1 className="networks">Market Places</h1>
              <h1 className="networks">SaaS Apps</h1>
              <h1 className="networks">Dashboards</h1>
              <h1 className="networks">CRM</h1>
            </TextLoop>

            <p className="ipsum">
              New venture? Upgrade your digital presence!
              <br />
              Crafting Apps for Growth 🚀
            </p>

            <a
              href="https://www.fiverr.com/s/YPbGoa"
              target="_blank"
              className="getInTouchBtn mt-5"
              style={{ color: "white" }}
            >
              <p>Get In Touch</p>
              <img className="pl-2" src={arrow}></img>
            </a>
          </div>
          <div className="col-md-5 sliderbg">
            <div
              style={{
                margin: "60px",
              }}
            >
              <TextLoop>
                <img
                  src={imagenames[0]}
                  style={{ width: "239px", height: "175px" }}
                />
                <img
                  src={imagenames[1]}
                  style={{ width: "239px", height: "175px" }}
                />
                <img
                  src={imagenames[2]}
                  style={{ width: "239px", height: "175px" }}
                />
                <img
                  src={imagenames[3]}
                  style={{ width: "239px", height: "175px" }}
                />
                <img
                  src={imagenames[4]}
                  style={{ width: "239px", height: "175px" }}
                />
              </TextLoop>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <p className="name">Discover the Numbers</p>
            <h1
              style={{
                fontSize: "30px !important",
                fontWeight: "700",
                color: "white",
              }}
            >
              Results
              <br />
              Matter
            </h1>
            <div className="d-flex">
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "white",
                  marginRight: "5px",
                }}
              >
                5.0
              </p>
              <svg
                width="25"
                height="30"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0L9.5161 4.22507L14.1574 4.83688L10.7621 8.05993L11.6145 12.6631L7.5 10.43L3.3855 12.6631L4.23788 8.05993L0.842604 4.83688L5.4839 4.22507L7.5 0Z"
                  fill="#6f4bff"
                ></path>
              </svg>
              <svg
                width="25"
                height="30"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0L9.5161 4.22507L14.1574 4.83688L10.7621 8.05993L11.6145 12.6631L7.5 10.43L3.3855 12.6631L4.23788 8.05993L0.842604 4.83688L5.4839 4.22507L7.5 0Z"
                  fill="#6f4bff"
                ></path>
              </svg>
              <svg
                width="25"
                height="30"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0L9.5161 4.22507L14.1574 4.83688L10.7621 8.05993L11.6145 12.6631L7.5 10.43L3.3855 12.6631L4.23788 8.05993L0.842604 4.83688L5.4839 4.22507L7.5 0Z"
                  fill="#6f4bff"
                ></path>
              </svg>
              <svg
                width="25"
                height="30"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0L9.5161 4.22507L14.1574 4.83688L10.7621 8.05993L11.6145 12.6631L7.5 10.43L3.3855 12.6631L4.23788 8.05993L0.842604 4.83688L5.4839 4.22507L7.5 0Z"
                  fill="#6f4bff"
                ></path>
              </svg>
              <svg
                width="25"
                height="30"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0L9.5161 4.22507L14.1574 4.83688L10.7621 8.05993L11.6145 12.6631L7.5 10.43L3.3855 12.6631L4.23788 8.05993L0.842604 4.83688L5.4839 4.22507L7.5 0Z"
                  fill="#6f4bff"
                ></path>
              </svg>
              <a
                href="https://www.fiverr.com/s/YPbGoa"
                target="_blank"
                style={{
                  fontSize: "15px",
                  fontWeight: "300",
                  color: "white",
                  marginTop: "3px",
                  marginLeft: "5px",
                }}
              >
                (250+)
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <p
              style={{
                fontSize: "75px",
                fontWeight: "500",
                color: "white",
              }}
            >
              100+
            </p>
            <h1
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: "white",
              }}
            >
              Happy Clients
            </h1>
          </div>
          <div className="col-md-3">
            <p
              style={{
                fontSize: "75px",
                fontWeight: "500",
                color: "white",
              }}
            >
              30+
            </p>
            <h1
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: "white",
              }}
            >
              Products Created
            </h1>
          </div>
          <div className="col-md-3">
            <p
              style={{
                fontSize: "75px",
                fontWeight: "500",
                color: "white",
              }}
            >
              10+
            </p>
            <h1
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: "white",
              }}
            >
              Team Members
            </h1>
          </div>
        </div>
      </div>

      {/* <div className="icons">
        <a href="tel:+971582175856" target="_blank">
          <img className="social-icon" src={phone}></img>
        </a>
        <a href="https://wa.me/971582175856?text=Hi%20Asad" target="_blank">
          <img className="social-icon" src={whatsapp}></img>
        </a>

        <a href="mailto:muhammadasadyaseen@gmail.com" target="_blank">
          <img className="social-icon" src={mail}></img>
        </a>
        <a
          href="https://www.linkedin.com/in/muhammad-asad-yaseen-draftsman/"
          target="_blank"
        >
          <img className="social-icon" src={linkdin}></img>
        </a>
        <div className="verticle-line"></div>
      </div> */}
      {/* <div className="e-mail">
        <p>muhammadasadyaseen@gmail.com</p>
        <div className="verticle-line"></div>
      </div> */}
    </div>
  );
}
export default Banner;
