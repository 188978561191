import React from "react";
import "../App.css";
import Banner from "./Banner";
import Blogs from "./Blogs";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ProjectPlan from "./ProjectPlan";

function Home() {
  return (
    <div className="wholeBg">
      <div>
        <div className="navbarPosition">
          <Navbar />
        </div>
        <Banner />
        <ProjectPlan />
        <Blogs />
        <Footer />
      </div>
    </div>
  );
}
export default Home;
